<template>
  <nav>
    <img alt="Vue logo" src="./assets/logo.png" height="200px" />
    <div id="nupud">
      <router-link to="/">Esileht</router-link> |
      <router-link to="/about">Meist</router-link>
    </div>
  </nav>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: larger;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
img {
  margin-top: 150px;
  margin-right: auto;
  position: fixed;
}
#nupud {
  text-align: center;
  flex-grow: 1;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
