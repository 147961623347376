<template>
  <div class="home">
    <HelloWorld msg="SaurusRehv" />
  </div>

  <div class="map-container">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4187.269134929371!2d26.73330521307064!3d58.34896628899664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eb37345e4baff3%3A0xb80556ade11633c2!2sSAURUSREHV%20O%C3%9C!5e0!3m2!1set!2sus!4v1730064825014!5m2!1set!2sus"
      width="600"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
};
</script>

<style scoped></style>
