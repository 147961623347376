<template>
  <div class="about">
    <h1>This is an about page</h1>
    <div class="parent">
      <div class="flex-container">
        <p>
          Saurusrehv OÜ asutaja Aivo Kade hakkas veoautorehvidega tegelema
          aastast 1991. Soomes saanud väljaõppe veoauto rehvide taastamises
          (kolm aastat töökogemust) ja Saksamaal veoauto-, traktorite- ja
          tööstusrehvide remontimises. Igapäevaselt praktiseerides ja
          analüüsides klientidelt saadud infot rehvide käitumisest erinevates
          oludes on kogutud korralik pagas teadmisi kvaliteetseks
          teenindamiseks. Parim tunnustus tehtud töödele on pidev püsiklientide
          lisandumine. Meilt kõik rehvitööd, rehvide taastamise tellime oma
          koostööpartneritelt.
        </p>
      </div>
      <div class="flex-container">
        <h2>PIC</h2>
        <img id=".center-image" src="../assets/logo.png" height="200px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutView",
};
</script>

<style scoped>
.parent {
  display: flex;
  justify-content: center;
  padding: 40px;
  height: 70vh; /* Full viewport height */
}

.flex-container {
  display: flex;
  width: 50vw;
  height: 50vh;
  align-items: center;
  justify-content: center;
  border: 10px solid #006eff;
  border-radius: 40px;
  margin: 50px;
  padding: 30px;
}

p {
  margin: 0;
}
</style>
